.text{
    font-family: Circular Std;
font-style: normal;
font-weight: normal;
font-size: 21.25px;
line-height: 28px;
/* or 133% */
/* display: flex; */
align-items: center;
font-feature-settings: 'liga' off;

/* Dark blue */
color: #091638;
}
.aboutus{
    font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 69px;
/* or 138% */
display: flex;
align-items: center;

/* Dark blue */
color: #091638;

}
.aboutimg{
     height: auto; width: 100%; margin: 20px auto;padding:25px 0px;
}
.aboutContent{
    padding-top:50px;
}
@media(max-width:767px){
    .aboutimg{
      width: 100%; 
      margin:20px auto !important;
      padding:10px 0px;
}
.aboutContent{
    padding-top:0px;
}

}