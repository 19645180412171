.header{
    /* display: flex; */
    /* justify-content: space-around; */
    background: #ffffff;
  }
  .header-content {
   padding-top: 30px;
  }
  .header-button {
    /* display: flex; */
    /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-evenly; */
  font-size: 20px; 
  font-weight: 400;
   color: #091638;
   
  
  }
  .sign-in {
    color: #ffffff;
    background: #eb5757;
    border-radius: 4px;
    border: none;
    font-size: 18;
    font-weight: 400;
    border-radius: 4px;
  }
  .post-property {
    background: #1c3988;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    font-size: 18;
    font-weight: 400;
    border-radius: 4px;
  }
  .ant-image{
    z-index:99 !important;
  }
  @media(max-width:767px){
    .header-content {
       padding-top: 10px;
  }
  .ant-image-img{
    float:left !important;
    margin-left:-40px !important;
  }
  /* .ant-layout-header{margin:5px 30px 10px} */
  }
  .ant-layout-header .header{
    padding-left: 50px !important;
    padding-right: 50px!important;
    padding-bottom: 25px !important;
  }