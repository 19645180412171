.TimelineDetails1{
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 20px;
color: #333333;
}
.timeline-icon{
font-size: 24px;
color: #E0E0E0;
}
.TimelineDetails2{
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 20px;
color: #828282;
}
.timeDetail{
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;
color: #BDBDBD;

}
.ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border: 1px dashed #E0E0E0;
}
.activityDivider.ant-divider-horizontal {
    margin-top: 0px !important;
}