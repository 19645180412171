.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
.dashboard_reminders{
  min-width: 300px;
}
body{
  font-family: 'IBM Plex Sans', sans-serif !important;
  overflow-x:hidden !important;
  /* overflow-y:hidden !important; */
}
.ant-image{
  margin-right:5px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cWidget{
  padding:100px !important;
}
.ant-typography{
  font-family: 'IBM Plex Sans', sans-serif !important;
}
a.ant-typography{
  color:#fff !important;
}


