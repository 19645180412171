.property_heading {
    position: absolute;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 69px;
  display: flex;
  align-items: center;
  color: #091638;
  }
  .property_sub_heading {
    position: absolute;
  width: 611px;
  height: 69px;
   /*font-family: Circular Std;*/ 
  font-style: normal;
  font-weight: normal;
  font-size: 21.25px;
  line-height: 28px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #091638;
  }
  .section-padding{padding-top:100px;padding-bottom:100px;}

/* .propertyCard .ant-card{
  margin:0px 76px 0px 0px
}
@media (max-width:1600px){
  .propertyCard .ant-card{
  margin:0px 10px 0px 0px
}
} */
