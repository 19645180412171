.group1{position:absolute;left:0px;top:0px;}
 .banner{background:linear-gradient(198.73deg, rgba(0, 0, 0, 0.0001) 15.31%, rgba(0, 0, 0, 0.837986) 67.37%);}
 .banner_image{margin-top:-110px;background-image:url(../assets/images/banner.png);background-position:center;position:relative;background-repeat:no-repeat;background-size:cover;height:100%;width:100%;min-height:992px;}

 .left_taxt{position:absolute;width:545px;height:176px;left:159px;top:250px;font-family:IBM Plex Sans;font-style:normal;font-weight:bold;font-size:75px;line-height:86px;display:flex;align-items:center;color:#FFFFFF;}
 .left_taxt1{position:absolute;width:544px;height:104px;left:160px;top:430px;font-style:normal;font-weight:normal;font-size:23px;line-height:34px;display:flex;align-items:center;font-feature-settings:'liga' off;color:#FFFFFF;}
 .search_box{position:absolute;background:#F3F3FA;border:1px solid #BDBDBD;box-sizing:border-box;border-radius:4px;}
 .property_type_box{position:absolute;left:20px;top:20px;background:#FFFFFF;border:1px solid #BDBDBD;box-sizing:border-box;box-shadow:inset 0px 1px 5px rgba(0, 0, 0, 0.1);border-radius:4px }
 .search_location{position:absolute;left:-70px;top:20px;background:#FFFFFF;border:1px solid #BDBDBD;box-sizing:border-box;box-shadow:inset 0px 1px 5px rgba(0, 0, 0, 0.1);border-radius:4px;}
 .search_button{position:absolute;left:-50px;top:20px;color:#FFFFFF;background:#1C3988;border:1px solid #BDBDBD;box-sizing:border-box;border-radius:4px;}
 .advance_search{position:absolute;width:136px;height:29px;left:160px;top:700px;font-style:normal;font-weight:bold;font-size:18px;line-height:28px;display:flex;align-items:center;color:#FFFFFF;}
 .project_gallery{position:absolute;width:838px;height:86px;font-family:IBM Plex Sans;font-style:normal;font-weight:bold;font-size:50px;line-height:69px;display:flex;align-items:center;color:#091638;}
 .project_gallery_sub_heading{position:absolute;width:611px;height:69px;font-style:normal;font-weight:normal;font-size:21.25px;line-height:28px;display:flex;align-items:center;font-feature-settings:'liga' off;color:#091638;}
 .property_heading{position:absolute;font-family:IBM Plex Sans;font-style:normal;font-weight:bold;font-size:50px;line-height:69px;display:flex;align-items:center;color:#091638;}
 .property_sub_heading{position:absolute;width:611px;height:69px;font-style:normal;font-weight:normal;font-size:21.25px;line-height:28px;display:flex;align-items:center;font-feature-settings:'liga' off;color:#091638;}
 .card_heading{font-family:IBM Plex Sans;font-style:normal;font-weight:bold;font-size:21.248px;line-height:28px;color:#141B2D;}
 .location{position:absolute;font-style:normal;font-weight:normal;font-size:17px;line-height:22px;color:#141B2D;}
 .price{font-family:Open Sans;font-style:normal;font-weight:bold;font-size:24px;line-height:27px;color:#1C3988;}
 .button{position:absolute;left:67.61%;right:4.26%;top:39.55%;bottom:57.09%;font-style:normal;font-weight:bold;font-size:14px;line-height:18px;display:flex;align-items:center;text-align:center;color:#FFFFFF;}
 .viewimages{position:absolute;left:5.68%;right:64.49%;top:40.49%;bottom:56.16%;font-style:normal;font-weight:bold;font-size:14px;line-height:18px;color:#FFFFFF;}
 .typography{font-style:normal;font-weight:normal;font-size:22px;line-height:22px;color:#141B2D;}

.newPropertyContent{
  margin-top:-250px !important;
}
 .pForm{
   margin-top: -600px; padding:50px; margin-bottom: 100px;
 }
 .pForm .pSectionTitle{
   padding:20px 0px;
   font-weight:bold;
 }
 .pForm .formcard{
   background:#F3F3FA;
 }

/* media query */
 @media (max-width:1600px)
 {
   .banner_image{margin-top:-100px;min-height:868px;}
 }
 @media (max-width:1440px)
 {
   .banner_image{margin-top:-83px;}
 }
 @media (max-width:1366px)
 {
   .banner_image{margin-top:-83px;}
 }
  @media (max-width:1280px)
 {
   .banner_image{margin-top:-67px;min-height:768px;}
    .pForm{
   margin-top: -500px; 
 }
 }
  @media (max-width:1024px)
 {
   .banner_image{margin-top:-42px;min-height:768px;}
 }
   @media (max-width:768px)
 {
   .banner_image{margin-top:-74px;min-height:564px;}
    .pForm{
   margin-top: -200px; 
   
 }
 .newPropertyContent{
  margin-top:-100px !important;
}
 }
  @media (max-width:767px)
 {
   .banner_image{margin-top:-80px;min-height:564px;}
   .pForm{
   margin-top: -170px; padding:20px; margin-bottom: 100px;
 }
 .pForm .pSectionTitle{
   padding:10px 0px;
   font-weight:bold;
 }
 .pForm .formcard{
   background:#F3F3FA;
 }
 .pForm .ant-card-body{
   padding:10px;
 }

 }