.footer_header{
    background: #1C3988;
}
.footer_text1{
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 34px;
color: #FFFFFF;
}
.footer_text2{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    margin-top: 20px;
    font-feature-settings: 'liga' off;
    color: #FFFFFF;
    opacity: 0.85;
}
.footer_text3{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    font-feature-settings: 'liga' off;
    color: #FFFFFF;
    opacity: 0.85;
}
.footer_text4{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-top: 40px;
    color: #F2F2F2;
}
.quick_links{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    opacity: 0.5;
}
.quick_links_buy{
     /*font-family: Circular Std;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px;
    color: #FFFFFF;
}
.quick_links_rent{
     /*font-family: Circular Std;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
}
.quick_links_sales{
     /*font-family: Circular Std;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
}
.company{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    opacity: 0.5;
}
.about_us{
     /*font-family: Circular Std;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px;
    color: #FFFFFF;
}
.contact_us{
     /*font-family: Circular Std;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
}
.property_service{
     /*font-family: Circular Std;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
}
.instagram_filled{
    color: #FFFFFF;
}
.youtube_filled{
    color: #FFFFFF;
}
.facebook_filled{
    color: #FFFFFF;
}
@media(max-width:768px){
    .footercontent{
        margin-top:20px;
        margin-left:-25px
    }
}

@media(max-width:767px){
    .footercontent{
        padding:30px 0px 0px 10px;
        margin-top:0px;
        margin-left:0px
    }
    .social-links .ant-image-img {margin-left:0px !important;}
}