.error_style {
    color: red;
    display: flex;
    /* justify-content: center; */
    font-size: 12px;
}

.create_client_field {
    margin: 15px;
    /* min-width: 326px; */
}
.create_checkbox {
    margin: 15px;
}
.notes_box {
    padding: 15px;
}

/* .ant-modal-content {
    width: 760px;
} */

.rowWithPadding {
    padding: 15px 15px 0 15px;
    /* margin-bottom: 0; */
}
.ant-table-thead > tr > th {
 font-size: 10px;
}
.ant-table-tbody > tr > td {
    font-size: 10px;
}
.clientName_button{
    font-size: 10px !important;
}