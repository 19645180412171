  
  .location {
    position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  color: #141B2D;
  }
  .price {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #1C3988;
  }
  .button{
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }
  .viewimages{
    position: absolute;
    left: 5.68%;
    right: 64.49%;
    top: 40.49%;
    bottom: 56.16%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
  }

  .typography1{
  font-size:18px;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 28px;
  color: #141B2D;
  font-style: normal;
font-weight: bold;
  }
  .typography2{
    font-family: 'IBM Plex Sans', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 17px;
line-height: 22px;
color: #141B2D;
  }
  .semi_furnished{
    font-family: 'IBM Plex Sans', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 18px;
padding-left: 5px;
/* identical to box height */

/* text color */
color: #141B2D;
  }
  .end{
    font-family: 'IBM Plex Sans', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
/* identical to box height */
margin-top: 30px;
/* Gray 3 */
color: #828282;
  }
  .pCard{
  
					max-width: 320px;
          width:100%;
					border: 1px solid #F3F3FA;
					border-radius: 5;
					/* position: relative; */
				
  }
  .pcCard{
  
    max-width: 320px;
    width:100%;
    min-height: 530;
    border: 1px solid #F3F3FA;
    border-radius: 5;
    /* position: relative; */
  
}
  .pCardImage{
    max-width:320px;
    width:100%;
    /* height:250px; */
  }