.project_gallery{
    position: absolute;
  width: 838px;
  height: 86px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 69px;
  display: flex;
  align-items: center;
  color: #091638;
  }
  .project_gallery_sub_heading{
    position: absolute;
  width: 611px;
  height: 69px;
   /*font-family: Circular Std;*/
  font-style: normal;
  font-weight: normal;
  font-size: 21.25px;
  line-height: 28px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #091638;
  }
  .gallary-padding{padding-bottom:100px}