.heading{
font-family: 'IBM Plex Sans', sans-serif !important;
font-style: normal;
font-weight: bold;
font-size: 17.248px;
line-height: 28px;
color: #141B2D;
}
.details{
font-family: 'IBM Plex Sans', sans-serif !important;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 22px;
color: #141B2D;
}
.card{
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
/* width: 350px; */
margin-right:20px;
}